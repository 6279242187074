@import "./variables";
// BOOTSTRAP SM BREAKPOINT 560PX

.dashboard-nav {
    padding: 0 !important;
    flex-direction: row;
    @media screen and (max-width: $br-lg){
        flex-direction: column;
    }
    .dashboard-nav-items {
        width: 15em !important;
        min-height: 100vh;
        flex-direction: column;
        @media screen and (max-width: $br-lg) {
            flex-direction: row;
            margin-top: 1em;
            width: 100% !important;
            min-height: fit-content !important;
        }
    }
}



// .Participants {
//     margin-left: 1em;
// }



